import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'rsuite';
import { Card } from 'react-bootstrap';
import { TextFieldComponent, TextFieldPassword } from '../../components';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Stack,
} from '@mui/material';

import {
  getCurrencyTypes,
  getDepositTypes,
  getPurposeTypes,
  getCountriesData,
  getRemittanceStatuses,
  getRedeemStatuses,
  getKycDocTypes,
  getRemittanceTypes,
  getAccountTypes,
} from '../../redux/actions/config';
import { getPermissions, getProfileData } from '../../redux/actions/profile';
import { getNotificationHistory } from '../../redux/actions/notification';
import { Routes } from '../../routes';
import * as API from '../../ApiUrl';
import mail from '../../assets/img/icons/mail.svg';
import stblogo from '../../assets/img/icons/InvestvertexWhite.png';

import ReCAPTCHA from 'react-google-recaptcha';
import { getWallets, getCoins } from '../../redux/actions/wallet';
import { getCountries } from 'react-phone-number-input';
import Image from '../../components/image';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string('Enter your password').required('Password is required'),
});

const Signin = ({ changepage }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  // console.log(process.env.REACT_APP_STB_ENV);

  const onSubmit = async (values) => {
    console.log(values, 'hehe');
    setLoading(true);
    try {
      const response = await axios.post(API.LOGIN_API, {
        ...values,
        token: captchaToken,
      });
      const token = response.data.data.token;
      console.log('login data', token);
      localStorage.setItem('accessToken', token?.access);
      localStorage.setItem('refreshToken', token?.refresh);
      localStorage.setItem('user-info', JSON.stringify(response.data.data));

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token?.access}`,
        },
      };

      // try {
      //   const response = await merchantApi.get(
      //     `${API.COUNTRY_LIST}?limit=1000`,
      //     config
      //   );
      //   const {
      //     data: { results },
      //   } = response;
      //   localStorage.setItem('country-list', JSON.stringify(results));
      // } catch (err) {
      //   toast.error(err?.data?.data?.message || 'Something went wrong');
      // }
      dispatch(getCountriesData())
      dispatch(getProfileData());
      dispatch(getPermissions());
      dispatch(getCurrencyTypes());
      dispatch(getDepositTypes());
      dispatch(getPurposeTypes());
      dispatch(getRemittanceStatuses());
      dispatch(getRedeemStatuses());
      dispatch(getKycDocTypes());
      dispatch(getRemittanceTypes());
      // dispatch(getAccountTypes());
      dispatch(getWallets());
      dispatch(getCoins());

      toast.success('Signed in successfully');
      setLoading(false);
      history.replace('/');
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message || 'Something went wrong');
    }
  };

  const onCaptchaClick = async (value) => {
    setCaptchaToken(value);
    setIsCaptchaValid(true);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <main>
      <section className="vh-100 position-relative background">
        <Image
          src={stblogo}
          sx={{
            width: "250px",
            height: "200px",
            position: "absolute",
            top: "80px",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        {/* <img
          className="position-absolute top-5 start-50 translate-middle"
          alt="logo"
        /> */}

        {/* <a
          className="socials twitter"
          href="https://twitter.com/Spend_The_Bits"
          target="_blank"
          rel="noopener noreferrer"
        >
          @Spend_The_Bits
        </a>
        <a
          className="socials mail"
          href="mailto:help@spendthebits.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={mail} alt="mail" />
          help@spendthebits.com
        </a> */}
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              formik.handleSubmit();
            }
          }}
        >
          <Dialog
            open={true}
            disableEscapeKeyDown={true}
            fullWidth
            maxWidth={'xs'}
            // className="wallet-configure"
            BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0)' } }}
            PaperProps={{
              style: {
                boxShadow: 'none',
                borderRadius: '8px',
              },
            }}
          >
            <DialogTitle className="text-center">
              <p className="dialog-title fw-bolder">Sign in</p>
              <p>Welcome to InvestVertex</p>
            </DialogTitle>
            <DialogContent className="merchant-wallet mt-2 pb-0">
              <Stack spacing={1} direction={'column'}>
                <TextFieldComponent
                  formik={formik}
                  name={'email'}
                  type={'email'}
                  label={'Email'}
                />
                <TextFieldPassword
                  formik={formik}
                  name={'password'}
                  label={'Password'}
                />
              </Stack>
            </DialogContent>
            <div
              className=" text-primary mb-1 fw-bolder"
              style={{ paddingRight: '1.5rem' }}
            >
              <Link
                to={Routes.ForgotPassword.path}
                className="text-end pull-right"
              >
                Forgot password?
              </Link>
            </div>
            <DialogActions className="captcha">
              {/* {process.env.REACT_APP_STB_ENV === 'TEST' && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onCaptchaClick}
              />
            )} */}
              <Button
                disabled={!formik.isValid || !isCaptchaValid}
                className={`btn action-button primary_color mt-0 text-white grow-1 w-100 mt-4 ${
                  !formik.isValid || !isCaptchaValid ? 'disabled' : null
                }`}
                loading={loading}
                // type="submit"
                onClick={formik.handleSubmit}
                id={'login-button'}
              >
                Sign In
              </Button>
            </DialogActions>
            <div className="d-flex justify-content-center align-items-center mb-4">
              <span className="fw-normal">
                Dont have an account?
                <Card.Link
                  onClick={() => changepage('signup')}
                  className="fw-bold text-primary"
                >
                  {` Sign up `}
                </Card.Link>
              </span>
            </div>
          </Dialog>
        </form>
      </section>
    </main>
  );
};

export default Signin;
